<template>
  <div class="order-detail">
    <fm-table-new
      simple-filter
      :columns="columns"
      v-loadingx="loading"
      border="row"
      auto-height
      ref="table"
      size="small"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
    </fm-table-new>
  </div>
</template>

<script>
import {
  orderDetailRequest
} from '@/api'

import Volume from './volume'
import TableActions from '@/components/base/TableActions'

export default {
  name: 'OrderDetailInfo',
  components: { TableActions },
  props: {
    orderId: { type: Number, default: null }
  },
  created () {
    this.loadData()
  },
  computed: {
    tableActions () {
      return [{
        key: 'del',
        label: '删除'
      }]
    },
    columns () {
      return [{
        field: 'name',
        title: '药品名称',
        render (h, row) {
          return h('div', row.medicine.name)
        }
      },
      {
        field: 'manufacturer',
        title: '生产厂商',
        render (h, row) {
          return h('div', row.medicine.manufacturer)
        }
      },
      {
        field: 'packing',
        title: '药品规格',
        render (h, row) {
          return h('div', row.medicine.packing)
        }
      },
      {
        field: 'volume',
        width: 80,
        title: '采购数量',
        render: (h, rowData) => {
          return h(Volume, {
            props: {
              volume: rowData.volume,
              check: (volume) => {
                return volume > -1
              }
            },
            on: {
              change: (volume) => {
                rowData.volume = volume
                rowData.total = rowData.price * rowData.volume
              }
            }
          })
        }
      },
      {
        field: 'price',
        title: '单价（元）'
      },
      {
        field: 'total',
        title: '金额（元）'
      },
      {
        title: '操作',
        field: 'actions',
        width: 100,
        slot: 'actions',
        fixed: 'right',
        search: false,
        export: false,
        configurable: false
      }]
    }
  },
  methods: {
    tableAction ({ action, data }) {
      if (action === 'del') {
        const index = this.dataList.findIndex( v => v.medicine.id === data.medicine.id)
        this.dataList.splice(index, 1)
      }
    },
    async loadData () {
      if (this.orderId) {
        let parm = {
          orderId: this.orderId
        }
        const datas = await orderDetailRequest.get(parm)
        this.dataList = datas.map(v => {
          return {
            volume: v.volume,
            price: v.price,
            total: v.volume * v.price,
            medicine: {
              code: v.medicineCode,
              id: v.medicineId,
              manufacturer: v.medicineManufacturer,
              name: v.medicineName,
              packing: v.medicinePacking,
              shelfLife: v.medicineShelfLife,
              type: v.medicineType
            }
          }
        })
      }
    },
    add (medicine, tag, num, origin) {
      const index = this.dataList.findIndex(v => v.medicine.id === medicine.id)
      if (index < 0) {
        let price = null
        if (origin.type === 'share') {
          price = medicine.shareMedicine.price
        } else {
          price = medicine.mainbodyMedicine ? medicine.mainbodyMedicine.price : medicine.price
        }
        this.dataList.push({
          volume: num,
          price: price,
          total: price,
          medicine: medicine
        })
      } else {
        this.dataList[index].volume += num
        this.dataList.splice(index, 1, this.dataList[index])
      }
    }
  },
  data () {
    return {
      dataList: [],
      loading: false
    }
  }
}
</script>

<style lang="less" scope>
.order-detail {
  width: 100%;
  height: 100%;
}
.left-page {
  display: block;
  flex: 2;
}
.right-page {
  flex: 1;
}
</style>