<template>
  <div class="order-build" v-loadingx="loading">
    <div class="left-page">
      <fm-title title-text="采购申请" :title-menus="[{key: 'save', label: '采购单结算'}]" @clickTitleMenu="clickTitleMenu">
        <div style="height:100%;display:flex;align-items: center;">
          <fm-select v-model="chooseGroupId" @change="groupIdChange" :disabled="orderId && true" :clearable="!orderId && true" filterable placeholder="请选择供应商">
            <fm-option v-for="item in groupList" :key="item.id" :value="item.id" :label="item.mainbodyLabel"></fm-option>
          </fm-select>
        </div>
      </fm-title>
      <div class="left-c">
        <order-detail ref="refOrderDetail" :order-id="orderId"></order-detail>
      </div>
    </div>
    <div class="right-page" :useGroupId="useGroupId">
      <medicine-list ref="buildMedicineList" @choose="chooseMedicine" v-if="useGroupId" :group-id="useGroupId"></medicine-list>
    </div>
    <fm-modal :mask-closable="false" title="付款" v-model="modalPay" width="600px">
      <pay @payOver="payOver" type="give" @cancel="cancel" :order-id="orderId" v-if="orderId && modalPay"></pay>
    </fm-modal>
  </div>
</template>

<script>
import Pay from './detail/pay'

import {
  mainbodyMedicineGroupRequest,
  orderRequest
} from '@/api'

import MedicineList from '@/views/medicine/search/prescription'
import OrderDetail from './detail/index'
export default {
  components: {
    MedicineList,
    OrderDetail,
    Pay
  },
  async created () {
    this.orderId = this.$route.query.id || null
    this.loading = true
    if (this.orderId) {
      await this.loadOrder()
    }
    await this.loadGroupList()
    this.loading = false
  },
  methods: {
    async cancel () {
      this.modalPay = false
      await orderRequest.backSubmit(this.orderId)
      this.order.status = 'edit'
    },
    payOver () {
      this.$notice.info({
        title: '系统提示',
        desc: '下单完成'
      })
      this.$router.push({name: 'order.buy'})
    },
    setUseGroupId (value) {
      let hisUseGroupId = this.useGroupId
      this.useGroupId = value
      if (hisUseGroupId) {
        let vm = this
        this.$nextTick(() => {
          // vm.$refs['buildMedicineList'].loadData()
          vm.$refs['refOrderDetail'].dataList = []
        })
      }
    },
    groupIdChange (value) {
      this.chooseGroup = this.groupList.find(v => v.id === value)
      if (value !== this.useGroupId) {
        this.setUseGroupId(value)
      }
    },
    async loadGroupList () {
      this.groupList = await mainbodyMedicineGroupRequest.getBuy({
        type: 'common'
      })
      if (this.order && this.order.saleMainbodyId) {
        this.chooseGroup = this.groupList.find(v => v.mainbodyId === this.order.saleMainbodyId)
      } else {
        this.chooseGroup = this.groupList.length > 0 ? this.groupList[0] : null
      }
      this.chooseGroupId = this.chooseGroup ? this.chooseGroup.id : null
      this.setUseGroupId(this.chooseGroupId)
    },
    chooseMedicine (parm, tag, num, origin) {
      this.$refs['refOrderDetail'].add(parm, tag, num, origin)
    },
    async clickTitleMenu () {
      let parm = Object.assign({}, this.order)
      parm.saleMainbodyId = this.chooseGroup.mainbodyId
      parm.mainbodyMedicineGroupId = this.chooseGroup.id
      parm.details = this.$refs['refOrderDetail'].dataList.filter(v => v.volume > 0).map(v => {
        return {
          volume: v.volume,
          price: v.price,
          total: v.total,
          medicineId: v.medicine.id
        }
      })
      if (parm.details.length === 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '无明细，无法下单!'
        })
        return
      }
      this.loading = true
      if (!this.orderId) {
        this.orderId = await orderRequest.add(parm)
      } else {
        await orderRequest.update(this.orderId, parm)
      }
      await this.loadOrder()
      if (this.order.status === 'edit') {
        await orderRequest.submit(this.orderId)
      }
      this.modalPay = true
      this.$refs['refOrderDetail'].loadData()
      this.loading = false
    },
    async loadOrder () {
      let orders = await orderRequest.get({id: this.orderId})
      this.order = orders[0]
    },
  },
  data () {
    return {
      order: {},
      loading: true,
      orderDetail: [],
      groupList: [],
      useGroupId: null,
      chooseGroupId: null,
      chooseGroup: null,
      modalPay: false,
      orderId: null
    }
  }
}
</script>

<style lang="less" scope>
.order-build {
  margin: 1rem;
  background: #fff;
  display: flex;
  width: calc(100% - 2rem);
  height: calc(100% - 2rem);
}
.left-c {
  width: 100%;
  height: 0;
  flex: 1;
  position: relative;
}
.left-page {
  flex: 2;
  display: flex;
  flex-direction: column;
}
.right-page {
  flex: 1;
}
</style>